import React from "react";
import { Link } from "gatsby";
import FluidImage from "../../components/FluidImage/FluidImage";
import Section from "../../components/Section/Section";
import fadeInSlide from 'utils/animations/fade-in-slide';

import "./FeaturedGrid.scss";

const FeaturedGrid = ({ description, heading, items }) => {
  const [ cardsRef ] = fadeInSlide('x', 100, { animateChildren: true });
  const [ titleRef ] = fadeInSlide('y', 50);

  return (
    <Section className="FeaturedGrid">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <h3 ref={titleRef} className="title">{heading}</h3>
          </div>
          <div className="col-lg-6">
            <div className="description Body--small">{description}</div>
          </div>
        </div>
        <div ref={cardsRef} className="ItemsRow alpha0-children">
          {items &&
            items.map((item, i) => {
              return (
                <Link to={item.uri} className="Item" key={i}>
                  <div className="ItemImage">
                    <FluidImage image={item.baseFields?.previewImage} aspectRatio={4 / 3} />
                  </div>
                  <div className="ItemDetails">
                    <h5>{item.title}</h5>

                    {item.baseFields.previewDescription && (
                      <div
                        className="Body--small"
                        dangerouslySetInnerHTML={{
                          __html:
                            item.baseFields.previewDescription.length > 65
                              ? `${item.baseFields.previewDescription.substring(0, 65)}...`
                              : item.baseFields.previewDescription,
                        }}
                      />
                    )}
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </Section>
  );
};

export default FeaturedGrid;
