import React from "react";
import Img from "gatsby-image";

const FluidImage = ({ image, aspectRatio, altText = "", ...others }) => {

  if (image?.extension === "svg") {
    return <img src={image.src} alt={image.altText || altText} {...others} />;
  }

  const fluidProps = {
    ...image
  };

  if (aspectRatio) {
    fluidProps.aspectRatio = aspectRatio;
  }
  return (
    <Img
      fluid={fluidProps}
      alt={image?.altText || altText}
      {...others}
    />
  );
};

export default FluidImage;
